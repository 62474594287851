import * as Yup from 'yup';
import { InputFiledMessage } from './ErrorMessage';

export const UnitsMeasureValidation = () =>
  Yup.object().shape({
    unitCode: Yup.string()
      .max(20, 'Մաքսիմում 20 նիշ')
      .required(InputFiledMessage),
    unitName: Yup.string()
      .max(40, 'Մաքսիմում 40 նիշ')
      .required(InputFiledMessage),
  });
