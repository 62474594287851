export const EInvoicingActionType = (row) => {

    let operations = [
        {
            name: 'Գործառնության կատարում',
            value: 'operation',
        },
        {
            name: 'Բովանդակություն',
            value: 'operationByRows',
        },
        {
            name: 'Վճարում / Մուտքագրում',
            value: 'payment',
        },
        {
            name: 'Գործառնություններ',
            value: 'operations',
        },

        {
            name: 'Ջնջել',
            value: 'delete',
        },
    ];
    if (row.isSigned === false) {
        operations.push(
            {

                name: 'Ստորագրել',
                value: 'sign',

            }
        )
    }

    return operations;
}