import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { postRequest } from '../../../Api/Api';
import GetSentDataBtn from '../../../Components/GetSentDataBtn/GetSentDataBtn';
import Modal from '../../../Components/Modal/Modal';
import { updateSignFiledAction } from '../../../Redux/eInvoicingOperationsSlices/useAction';
import Calendar from '../../../Components/Calendar/Calendar';
import { dateFormat, formatSignDate } from '../../../Utilities/Utilities';


const SignForm = ({ closeModal, rowData, setErrorHandler }) => {
  const { signedByTheBuyer, excelInvoiceID } = rowData || {};

  const handleClose = () => closeModal(false);
  const reformattedDate = formatSignDate(signedByTheBuyer)


  const [signValue, setSignValue] = useState({
    excelInvoiceID: excelInvoiceID,
    signDate: reformattedDate || new Date(),
  });


  const dispatch = useDispatch();



  const handleSubmit = () => {
    const formatDate = signValue?.signDate
    postRequest('saveExcelInvoiceSign', signValue)
      .then((res) => {
        if (res.data.errorCode === 0) {
          dispatch(
            updateSignFiledAction({
              excelInvoiceID: excelInvoiceID,
              signedByTheBuyer: dateFormat(formatDate),
              isSigned: true,
            }),


          );

          handleClose();
        }
        if (res.data.errorCode > 0) {
          setErrorHandler(res.data.messages);
        }
      })
      .catch((err) => {
        console.log('err' + err);
      });
  };


  const signChangeHandler = (date) => {
    setSignValue((prevItem) => ({
      ...prevItem,
      signDate: date
    }));
  };

  const signDate = signValue.signDate ? signValue.signDate : new Date()



  return (
    <Modal closeHandler={handleClose} onDrag={true} customClass="L-sign-modal">
      <div className="L-sign-modal-content">

        <Calendar
          value={dateFormat(signDate)}
          changeHandler={(date, e) => signChangeHandler(date, e)}
          name="signDate"
        />

        <GetSentDataBtn
          cancelPrintClick={handleClose}
          confirmExecuteClick={handleSubmit}
        />
      </div>
    </Modal>
  );
};

export default SignForm;
