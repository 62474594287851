import { removeCommas } from '../../../App/Utilities/Utilities';

export const handleChangeHours = (
  e,
  row,
  newMakeAnAddition,
  setNewMakeAnAddition,
  setIsDisabled,
) => {
  const { value } = e.target;
  const newHours = Number(value);
  if (row.formulaID === 5) {
    const updatedRows = newMakeAnAddition?.map((r) =>
      r.empNo === row.empNo ? { ...r, hours: newHours } : r,
    );
    setNewMakeAnAddition(updatedRows);
    return;
  }
  const updatedRows = newMakeAnAddition?.map((r) => {
    if (r.salaryEntryID === row.salaryEntryID) {
      let newAmount = 0;

      if (r.formulaID === 2 && r.formSign === 2) {
        newAmount = r.salary * newHours * r.coeff;
      } else if (r.workHours > 0) {
        newAmount = (r.salary / r.workHours) * newHours * r.coeff;
      }
      setIsDisabled(false);
      return {
        ...r,
        hours: newHours,
        amount: Math.round(newAmount),
        isModifed: true,
      };
    } else {
      return r;
    }
  });

  setNewMakeAnAddition(updatedRows);
};

export const handleChangeCoefficient = (
  e,
  row,
  newMakeAnAddition,
  setNewMakeAnAddition,
  setIsDisabled,
) => {
  const { value } = e.target;
  const newCoefficient = value && parseFloat(value);
  if (row.formulaID === 5) {
    return;
  }
  const updatedRows = newMakeAnAddition?.map((r) => {
    if (r.salaryEntryID === row.salaryEntryID) {
      let newAmount = 0;

      if (r.formulaID === 2 && r.formSign === 2) {
        newAmount = r.salary * r.hours * newCoefficient;
      } else if (r.workHours > 0) {
        newAmount = (r.salary / r.workHours) * r.hours * newCoefficient;
      }
      setIsDisabled(false);
      return {
        ...r,
        coeff: newCoefficient,
        amount: Math.round(newAmount),
        isModifed: true,
      };
    } else {
      return r;
    }
  });

  setNewMakeAnAddition(updatedRows);
};

export const onAmountChange = (
  e,
  targetRow,
  newMakeAnAddition,
  setNewMakeAnAddition,
  setIsDisabled,
) => {
  const { value } = e.target;
  const newAmount = removeCommas(value);
  if (targetRow.formulaID === 5) {
    return;
  }
  const updatedRows = newMakeAnAddition?.map((row) => {
    if (targetRow.salaryEntryID === row.salaryEntryID) {
      const updatedRow = {
        ...row,
        isModifed: true,
        amount: newAmount,
        sign: true,
      };
      setIsDisabled(false);
      return updatedRow;
    }
    return row;
  });

  setNewMakeAnAddition(updatedRows);
};

export const handleCheckboxChange = (
  e,
  row,
  newMakeAnAddition,
  setNewMakeAnAddition,
  setIsDisabled,
) => {
  if (row.formulaID === 5) {
    return;
  }
  const { checked } = e.target;
  if (checked) {
    const checkAll = newMakeAnAddition.map((item) => {
      setIsDisabled(false);
      let sign = true;
      if (item.formulaID === 1) {
        if (item.formSign === 2) {
          item.amount = item.salary * item.workHours * item.coeff;
        } else {
          item.amount = item.salary * item.coeff;
        }
      } else if (item.formulaID === 2) {
        if (item.formSign === 2) {
          item.amount = Math.round(item.salary * item.hours * item.coeff, 2);
        } else {
          if (item.workHours > 0) {
            item.amount = Math.round(
              (item.salary / item.workHours) * item.hours * item.coeff,
              2,
            );
          } else {
            item.amount = 0;
          }
        }
      } else if (item.formulaID === 4) {
        if (item.formSign === 2) {
          item.amount = Math.round(item.hgAmount * item.hours * item.coeff, 2);
        } else {
          if (item.workHours > 0) {
            item.amount = Math.round(
              (item.hgAmount / item.workHours) * item.hours * item.coeff,
              2,
            );
          } else {
            item.amount = 0;
          }
        }
      } else if (item.formulaID === 3) {
        item.amount = item.hgAmount * item.coeff;
      }

      return { ...item, sign, isModifed: true };
    });

    setNewMakeAnAddition(checkAll);
  } else {
    const checkAllWithoutSign = newMakeAnAddition.map((item) => {
      return {
        ...item,
        sign: false,
        isModifed: true,
        amount: 0,
        hours: 0,
      };
    });
    setNewMakeAnAddition(checkAllWithoutSign);
  }
};

export const onCheckCurrentCheckbox = (
  e,
  row,
  newMakeAnAddition,
  setNewMakeAnAddition,
  setIsDisabled,
) => {
  const { checked } = e.target;
  if (row.formulaID === 5) {
    return;
  }
  let updatedList = newMakeAnAddition.map((item) => {
    setIsDisabled(false);
    if (item.salaryEntryID === row.salaryEntryID) {
      if (checked) {
        let sign = true;
        let amount = 0;

        if (item.formulaID === 1) {
          amount =
            item.formSign === 2
              ? item.salary * item.workHours * item.coeff
              : item.salary * item.coeff;
        } else if (item.formulaID === 2) {
          if (item.formSign === 2) {
            amount = Math.round(item.salary * item.hours * item.coeff, 2);
          } else {
            amount =
              item.workHours > 0
                ? Math.round(
                    (item.salary / item.workHours) * item.hours * item.coeff,
                    2,
                  )
                : 0;
          }
        } else if (item.formulaID === 3) {
          amount = item.hgAmount * item.coeff;
        }

        return { ...item, sign, amount, isModifed: true };
      } else {
        return {
          ...item,
          sign: false,
          isModifed: true,
          amount: 0,
          hours: 0,
          coeff: 0,
        };
      }
    } else {
      return item;
    }
  });

  setNewMakeAnAddition(updatedList);
};
