import React, { useEffect, useState } from 'react';
import Header from '../../../App/Components/Header/Header';
import './index.scss';
import ConfirmPositionsTable from './ConfirmPositionsTable';
import useConfirmPositions from '../../hooks/useConfirmPositions';
import ConfirmAction from './ConfirmAction';
import { simplifyObject } from '../../../App/Utilities/Utilities';

const ConfirmPositions = () => {
  const { getConfirmPositions, confirmPositions, getConfirmData, confirmData } =
    useConfirmPositions();
  const [query, setQuery] = useState({
    salaryDate: '',
    stNo: '',
    depNo: '',
    empNo: '',
  });

  const [isDisabledConfBtn, setIsDisabledConfBtn] = useState(false);

  useEffect(() => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      salaryDate: confirmPositions?.salaryDate,
    }));
  }, [confirmPositions?.salaryDate]);

  const modifyObj = simplifyObject(query);

  useEffect(() => {
    getConfirmPositions();
  }, []);

  const onExecuteHandler = async () => {
    try {
      await getConfirmData(modifyObj);
      setIsDisabledConfBtn(false);
    } catch (err) {
      console.error('An error occurred:', err);
    }
  };

  return (
    <div className="L-confirm-positions-wrap">
      <Header
        pageTitle="Հաստատել հաստիքները"
        headerBtn={false}
        showCalculationDate={true}
      />

      <ConfirmAction
        query={query}
        setQuery={setQuery}
        confirmPositions={confirmPositions}
        onExecuteHandler={onExecuteHandler}
        isExecuteDis={confirmPositions?.message}
        // isDisabledBtn={isDisabledBtn}
      />
      {confirmData?.message && (
        <p style={{ marginTop: '20px', textAlign: 'center' }}>
          {confirmData?.message}
        </p>
      )}
      <ConfirmPositionsTable
        mainData={confirmData?.tables}
        isDisabledConfBtn={isDisabledConfBtn}
        setIsDisabledConfBtn={setIsDisabledConfBtn}
        query={modifyObj}
        getConfirmData={getConfirmData}
      />
    </div>
  );
};

export default ConfirmPositions;
