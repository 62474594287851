import React, { useState } from "react";
import Loading from "../../Components/Loading/Loading";
import Table from "../../Components/Table/Table";
import Tooltip from "../../Components/Tooltip/Tooltip";
import { deleteRequest, getRequest } from '../../Api/Api';
import Action from '../../Components/Table/Action';
import {
  Commas,
  dateFormat,
  tableActionTitle,
} from '../../Utilities/Utilities';

import GetPaymentOrderData from './PaymentOrder/GetPaymentOrderData';
import GetBankEntryOrders from './BankEntryOrders/GetBankEntryOrders';
import GetOperationData from './OperationForm/GetOperationData';
import GetOperationsData from './Operations/GetOperationsData';
import SignForm from './SignForm/SignForm';

import AcceptOrCancelModal from '../../Components/AcceptOrCancelModal/AcceptOrCancelModal';
import OperationByRows from './OperationByRows/OperationByRows';
import { EInvoicingActionType } from "./EInvoicingActionType";

const EInvoicingOperationsTable = ({
  loading,
  setErrorHandler,
  generalData,
  setGeneralData
}) => {

  const [openOperationModal, setOpenOperationModal] = useState(false);
  const [operationData, setOperationData] = useState([]);

  const [openOperationsModal, setOpenOperationsModal] = useState(false);
  const [operationsData, setOperationsData] = useState([]);

  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [paymentData, setPaymentData] = useState([]);
  const [openSignModal, setOpenSignModal] = useState(false);
  const [openOperationByRow, setOpenOperationByRow] = useState(false);
  const [removeData, setRemoveData] = useState(false);

  const fetchInvoiceEnterData = (excelInvoiceID) => {
    getRequest(`getExcelInvoiceForEntry?excelInvoiceID=${excelInvoiceID}`)
      .then((res) => {
        setOperationData(res.data);
        setOpenOperationModal(excelInvoiceID);
      })
      .catch((err) => {
        console.log('Err' + err);
      });
  };

  const columnConfig = [
    {
      title: <Tooltip content="Ստորագրման ամսաթիվ">ՍՏ/Ա</Tooltip>,
      cell: (row) => row.signedByTheBuyer,
      customStyle: { maxWidth: 120 },
    },
    {
      title: 'Գործընկեր',
      cell: (row) => row.partnerName,
      customStyle: { maxWidth: 750, overflow: 'hidden' },
    },
    {
      title: 'ՀՎՀՀ',
      cell: (row) => row.partnerTaxCode,
      customStyle: { maxWidth: 70 },
    },
    {
      title: <Tooltip content="Սերիա և համարը">Ս/Հ</Tooltip>,
      cell: (row) => row.invoiceSerialNumber,
      customStyle: { maxWidth: 90 },
    },
    {
      title: <Tooltip content="Մատակարարման ա/թ">Մատ.ա/թ</Tooltip>,
      cell: (row) => dateFormat(row.invoiceDate),
      customStyle: { maxWidth: 70 },
    },
    {
      title: 'Արժեքը',
      cell: (row) => Commas(row.amount),
      customStyle: { maxWidth: 150 },
    },
    {
      title: 'ԱԱՀ',
      cell: (row) => Commas(row.valueAddedTax),
      customStyle: { maxWidth: 150 },
    },
    {
      title: 'Դեբետ',
      cell: (row) => row.accountDebit,
      customStyle: { maxWidth: 90 },
    },
    {
      title: 'Կրեդիտ',
      cell: (row) => row.accountCredit,
      customStyle: { maxWidth: 90 },
    },
    {
      title: tableActionTitle,
      cell: (row) => (
        <Action
          row={row}
          mainAction="operation"
          mainActionName="Գործառնության կատարում"
          handelChange={handelChange}
          actionItem={EInvoicingActionType(row)}
          modalIsOpen={
            openOperationModal ||
            openOperationsModal ||
            openPaymentModal ||
            openSignModal ||
            removeData ||
            openOperationByRow
          }
        />
      ),
      customStyle: { maxWidth: 190 },
    },
  ];

  const handelChange = (value, row) => {
    switch (value) {
      case 'operation':
        return openOperationForm(row?.excelInvoiceID);
      case 'payment':
        return openPaymentForm(row);
      case 'operations':
        return openOperationsForm(row?.excelInvoiceID);
      case 'sign':
        return openSignForm(row);
      case 'delete':
        return setRemoveData(row?.excelInvoiceID);
      case 'operationByRows':
        return setOpenOperationByRow(row?.excelInvoiceID);
      default:
        return null;
    }
  };

  const removeHandler = () => {

      deleteRequest(`deleteExcelInvoice/${removeData}`).then((res) => {
        if (res.data.errorCode === 0) {
          const newList = generalData.filter(
            (item) => item.excelInvoiceID !== removeData,
          );
          setGeneralData(newList);
          setRemoveData(false);
        }
        else if (res.data.errorCode > 0) {
          setErrorHandler(res.data.message);
        }
      });

  };

  const openOperationForm = (excelInvoiceID) => {
    if (excelInvoiceID) {
      fetchInvoiceEnterData(excelInvoiceID);
    }
  };

  const openOperationsForm = (excelInvoiceID) => {
    if (excelInvoiceID) {
      getRequest(`getExcelInvoiceEntries?excelInvoiceID=${excelInvoiceID}`)
        .then((res) => {
          setOperationsData(res.data);
          setOpenOperationsModal(true);
        })
        .catch((err) => {
          console.log('Err' + err);
        });
    }
  };

  const openPaymentForm = (rowData) => {
    const { excelInvoiceID, recivedWrittenOff } = rowData;
    if (recivedWrittenOff === 1) {
      getRequest(`getInvoicePaymentOrder?excelInvoiceID=${excelInvoiceID}`)
        .then((res) => {
          if (res.data.message === null) {
            setPaymentData(res.data);
            setOpenPaymentModal({ recivedWrittenOff, excelInvoiceID });
          }
          if (res.data.message !== null) {
            setOpenPaymentModal(false);
            setErrorHandler(res.data.message);
          }
        })
        .catch((err) => {
          console.log('Err' + err);
        });
    }

    if (recivedWrittenOff === 2) {
      getRequest(`getInvoiceBankOrder?excelInvoiceID=${excelInvoiceID}`)
        .then((res) => {
          if (res.data.message === null) {
            setPaymentData(res.data);
            setOpenPaymentModal({ recivedWrittenOff, excelInvoiceID });
          }
          if (res.data.message !== null) {
            setOpenPaymentModal(false);
            setErrorHandler(res.data.message);
          }
        })
        .catch((err) => {
          console.log('Err' + err);
        });
    }
  };

  const openSignForm = (row) => {
    const { signedByTheBuyer, excelInvoiceID } = row || {};

    setOpenSignModal({ signedByTheBuyer, excelInvoiceID });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {generalData !== null && generalData && generalData?.length ? (
        <Table
          customClass="L-EInvoicing-operations-table"
          data={generalData}
          columnConfig={columnConfig}
        />
      ) : (
        ''
      )}

      {openOperationModal && (
        <GetOperationData
          closeModal={setOpenOperationModal}
          operationData={operationData}
          excelInvoiceID={openOperationModal}
          setErrorHandler={setErrorHandler}
          fetchData={fetchInvoiceEnterData}
        />
      )}

      {openPaymentModal?.recivedWrittenOff === 1 && (
        <GetPaymentOrderData
          closeModal={setOpenPaymentModal}
          excelInvoiceID={openPaymentModal?.excelInvoiceID}
          paymentData={paymentData}
          setErrorHandler={setErrorHandler}
        />
      )}

      {openPaymentModal?.recivedWrittenOff === 2 && (
        <GetBankEntryOrders
          closeModal={setOpenPaymentModal}
          excelInvoiceID={openPaymentModal?.excelInvoiceID}
          paymentData={paymentData}
          setErrorHandler={setErrorHandler}
        />
      )}

      {openOperationsModal && (
        <GetOperationsData
          operationsData={operationsData}
          closeModal={setOpenOperationsModal}
          setOperationsData={setOperationsData}
        />
      )}

      {openSignModal && (
        <SignForm
          rowData={openSignModal}
          closeModal={setOpenSignModal}
          setErrorHandler={setErrorHandler}
          setOperationsData={setOperationsData}
        />
      )}

      {removeData && (
        <AcceptOrCancelModal
          closeModal={setRemoveData}
          confirmClick={removeHandler}
        />
      )}
      {openOperationByRow && (
        <OperationByRows
          excelInvoiceID={openOperationByRow}
          closeModal={setOpenOperationByRow}
        />
      )}
    </>
  );
};

export default EInvoicingOperationsTable;
