export const UnitsMeasureField = [
  {
    fieldType: 'input',
    type: 'text',
    name: 'unitCode',
    label: 'Չափման միավորի համառոտ անվանումը',
    value: '',
    required: true,
    maxLength: 3,
  },

  {
    fieldType: 'input',
    type: 'text',
    name: 'unitName',
    label: 'Անվանումը',
    value: '',
    required: true,
    maxLength: 50,
  },
];
