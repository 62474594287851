import React from 'react';
import Alert from '../../../App/Components/Alert';
import useIsMounted from '../../hooks/useIsMounted';
import PartnersForm from './PartnersForm';
import usePartner from '../../hooks/usePartner';
import { useSelector } from 'react-redux';

const EventAddForm = ({ handleClose, params }) => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const { addPartner, errorMess, setErrorMess } = usePartner();
  const isMounted = useIsMounted();
  const initialValues = {
    companyID: currentCompanyID,
    partnerName: '',
    partnerForm: false,
    partnerType: '',
    taxeServiceNumber: '',
    taxCode: '',
    basis: '',
    description: '',
    countryType: 0,
    idCard: '',
    passport: '',
  };
  const onAddHandler = async (values, resetForm) => {
    try {
      await addPartner(values, handleClose, params);

      if (isMounted && !errorMess) {
        resetForm();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <>
      <PartnersForm
        partnerValues={initialValues}
        onSubmit={onAddHandler}
        handleClose={handleClose}
      />
      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default EventAddForm;
