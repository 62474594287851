import React from 'react';
import MatValueGroupForm from '../materialValueGroups/EventAddForm';
import MatValueUnitForm from '../materialValues​UnitsMeasure/EventAddForm';

const NewItemForms = ({ formType, closeModal, setFieldValue }) => {
  const handleClose = () => closeModal(false);

  switch (formType) {
    case 'groupName':
      return (
        <MatValueGroupForm
          setFieldValue={setFieldValue}
          handleClose={handleClose}
          formIsUsedExternally={true}
        />
      );
    case 'unit':
      return (
        <MatValueUnitForm
          handleClose={handleClose}
          setFieldValue={setFieldValue}
          formIsUsedExternally={true}
        />
      );

    default:
      return null;
  }
};

export default NewItemForms;
