import React, { useEffect, useState } from 'react';
import Alert from '../../Components/Alert';
import Button from '../../Components/Button/Button';
import CalendarTime from '../../Components/Calendar/CalendarTime';
import SearchBtn from '../../Components/SearchBtn/SearchBtn';
import SelectCurrency from '../../Components/SelectBox/SelectCurrency';
import SelectDocTypes from '../../Components/SelectBox/SelectDocTypes';
import SelectPartner from '../../Components/SelectBox/SelectPartner';
import SelectDocNum from '../../Components/SelectFiled/SelectDocNum';
import { correctDate, inCorrectDateMess } from '../../Utilities/Utilities';
import { ExportExcel } from '../../Components/Excelexport/Excelexport';
import SearchBackground from '../../Components/SearchForm/SearchBackground';
const DocumentSearchBar = (props) => {
  const {
    dateStart,
    dateEnd,
    setDateStart,
    setDateEnd,
    searchValue,
    setSearchValue,
    getData,
    pageNumber,
    mainData,
    printHandler,
    setPageNumber,
    getExcelData,
  } = props;

  const [docTypesVal, setDocTypesVal] = useState({
    label: '',
    value: '',
    id: '',
  });

  const [partnerVal, setPartnerVal] = useState({
    label: '',
    value: '',
    id: '',
  });

  const [currencyVal, setCurrencyVal] = useState({
    label: '',
    value: '',
    id: '',
  });
  const [errorHandler, setErrorHandler] = useState('');
  const timeChangeHandler = (val, name) => {
    const isCorrect = correctDate(val, name, dateStart, dateEnd);
    if (!isCorrect) {
      setErrorHandler(inCorrectDateMess);
      return;
    }

    if (name === 'dateStart') {
      setDateStart(val);
    } else if (name === 'dateEnd') {
      setDateEnd(val);
    }
  };

  const handelSearchClick = () => {
    const keys = Object.keys(searchValue);
    setPageNumber(0);
    if (keys.every((el) => el !== '')) {
      getData(pageNumber, searchValue);
    } else {
      getData(pageNumber);
    }
  };

  useEffect(() => {
    const searchValueObj = {
      currencyID: currencyVal.id,
      docTypeID: docTypesVal.id,
      partnerID: partnerVal.id,
    };
    setSearchValue({
      ...searchValue,
      ...searchValueObj,
    });
  }, [currencyVal.id, docTypesVal.id, partnerVal.id]);

  const selectChange = (value, id) => {
    if (!value) {
      value = {
        value: '',
      };
    }
    if (id === 'docTypes') {
      setDocTypesVal(value);
    } else if (id === 'partner') {
      setPartnerVal(value);
    } else if (id === 'currency') {
      setCurrencyVal(value);
    }
  };

  return (
    <>
      <SearchBackground>
        <div className="L-document-search-box">
          <CalendarTime
            dateStart={dateStart}
            setDateStart={setDateStart}
            dateEnd={dateEnd}
            setDateEnd={setDateEnd}
            onChangeHandler={timeChangeHandler}
          />
          <SelectDocNum
            changeHandler={(e) =>
              setSearchValue({ ...searchValue, docNum: e.target.value })
            }
          />
          <SelectDocTypes
            docTypesData={mainData?.docTypes}
            docTypesVal={docTypesVal}
            selectChangeDocType={selectChange}
          />
          <SelectPartner
            partnerData={mainData?.partners}
            partnerVal={partnerVal}
            selectChangePartner={selectChange}
          />
          <SelectCurrency
            requiredFiled={false}
            currencyData={mainData?.currencies}
            currencyVal={currencyVal}
            selectChangeCurrency={selectChange}
          />
        </div>
      </SearchBackground>

      <div className="L-document-search-btn">
        <SearchBtn onClick={handelSearchClick} />
        <div className="G-print-excel-block">
          <div className="G-view-btn">
            <Button text="Դիտել/տպել" onClick={printHandler} />
          </div>
          <ExportExcel getExcelData={getExcelData} />
        </div>
      </div>

      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default DocumentSearchBar;
