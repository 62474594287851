import React from 'react';
import Modal from '../../../Components/Modal/Modal';
import OperationsHeader from './OperationsHeader';
import OperationsTable from './OperationsTable';

const GetOperationsData = ({
  operationsData,
  closeModal,
  setOperationsData,
}) => {
  const handleClose = () => closeModal(false);

  return (
    <Modal
      closeHandler={handleClose}
      onDrag={true}
      customClass="L-operations-modal"
    >
      <>
        <div className="G-modal-block-title">
          <h3>Գործառնություններ</h3>
        </div>
        <OperationsHeader mainData={operationsData} />
        <OperationsTable
          mainData={operationsData}
          setOperationsData={setOperationsData}
        />
      </>
    </Modal>
  );
};

export default GetOperationsData;
