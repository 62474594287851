import {
  GET_INVOICING_OPERATIONS_DATA,
  LOADING,
  UPDATE_SIGN_FILED,
} from './constants';

const initialState = {
  invoicingOperationData: null,
  isLoading: false,
};

export const invoicingOperationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_INVOICING_OPERATIONS_DATA:
      return {
        ...state,
        invoicingOperationData: payload,
        isLoading: true,
      };

    case UPDATE_SIGN_FILED:
      return {
        ...state,
        invoicingOperationData: {
          ...state.invoicingOperationData,
          invList: [
            ...state.invoicingOperationData.invList.map((item) => {
              const updateData =
                item.excelInvoiceID === payload.excelInvoiceID
                  ? {
                      ...item,
                    signedByTheBuyer: payload.signedByTheBuyer,
                    isSigned: payload.isSigned
                    }
                  : item;
              return {
                ...updateData,
              };
            }),
          ],
        },
      };


    case LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    default:
      return { ...state };
  }
};


