import React from 'react';
import Table from '../../../App/Components/Table/Table';
import { Commas, dateFormat } from '../../../App/Utilities/Utilities';
import checkIcon from '../../assets/icons/check.svg';
const AccountingFormulationTable = ({ formulationsTable }) => {
  const columnConfig = [
    {
      title: 'Դեբետ',
      cell: (row) => row.accountDebit,
      customStyle: { maxWidth: 130 },
    },
    {
      title: 'Կրեդիտ',
      cell: (row) => row.accountCredit,
      customStyle: { maxWidth: 130 },
    },
    {
      title: 'Գումարը',
      cell: (row) => Commas(row.amount),
      customStyle: { maxWidth: 180 },
    },
    {
      title: 'Ամսաթիվը',
      cell: (row) => dateFormat(row.docDate),
      customStyle: { maxWidth: 90 },
    },

    {
      title: 'Նշումներ',
      cell: (row) => row.correspName,
      customStyle: { maxWidth: 1050, overflow: 'hidden' },
    },
    {
      title: 'Հաստատված',
      cell: (row) =>
        row.entryID > 0 ? (
          <img className="G-confirm-icon" src={checkIcon} alt="checkIcon" />
        ) : null,
      customStyle: { maxWidth: 100 },
    },
  ];
  return (
    <div>
      {formulationsTable !== null && formulationsTable?.length ? (
        <Table
          customClass="L-accounting-formulation-table G-table-has-scroll"
          data={formulationsTable}
          columnConfig={columnConfig}
        />
      ) : null}
    </div>
  );
};

export default AccountingFormulationTable;
