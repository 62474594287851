import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  fetchEmployeesForCalc,
  fetchExecuteSalaryCalculator,
  fetchSalaryCalculation,
  removeCalculateData,
  saveCalculateData,
} from '../services/fetchSalaryCalculation';

const useSalaryCalculation = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [error, setError] = useState('');
  const [errorMess, setErrorMess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [salaryCalculation, setSalaryCalculation] = useState(null);
  const [employeesForCalc, setEmployeesForCalc] = useState(null);
  const getSalaryCalculation = async () => {
    setIsLoading(true);
    try {
      const data = await fetchSalaryCalculation(currentCompanyID);

      setSalaryCalculation(data);
    } catch (error) {
      setError('Error fetching data');
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getEmployeesForCalc = async (depNo) => {
    setIsLoading(true);
    try {
      const data = await fetchEmployeesForCalc(currentCompanyID, depNo);
      setErrorMess(data.message);
      setEmployeesForCalc(data);
    } catch (error) {
      setError('Error fetching data');
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getExecuteSalaryCalculator = async (query) => {
    setIsLoading(true);
    try {
      const data = await fetchExecuteSalaryCalculator(currentCompanyID, query);
      setErrorMess(data?.message ? data?.message : 'Հաշվարկն ավարտվեց');
    } catch (error) {
      setError('Error fetching data');
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const onCalculateData = async (depNo, newData) => {
    try {
      const { errorCode, message } = await saveCalculateData(
        currentCompanyID,
        depNo,
        newData,
      );

      getEmployeesForCalc(depNo);
      if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    }
  };
  useEffect(() => {
    return () => {
      setError('');
      setErrorMess('');
      setIsLoading(false);
    };
  }, []);

  const getRemoveCalculateData = async (depNo, removeData) => {
    try {
      const { errorCode, message } = await removeCalculateData(
        currentCompanyID,

        removeData,
      );

      getEmployeesForCalc(depNo);
      if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    }
  };
  console.log(salaryCalculation, 'salaryCalculation');
  return {
    getSalaryCalculation,
    salaryCalculation,
    getEmployeesForCalc,
    employeesForCalc,
    getExecuteSalaryCalculator,
    isLoading,
    onCalculateData,
    getRemoveCalculateData,
    error,
    errorMess,
    setErrorMess,
  };
};

export default useSalaryCalculation;
