import React, { useEffect } from 'react';
import Modal from '../../../App/Components/Modal/Modal';
import { useFormik } from 'formik';
import GetSentDataBtn from '../../../App/Components/GetSentDataBtn/GetSentDataBtn';
import { warehouseField } from './FieldsConfig';
import useIsMounted from '../../hooks/useIsMounted';
import Input from '../../components/Input/Input';
import useWarehouse from '../../hooks/useWarehouse';
import { WarehouseFormValidation } from '../../../App/Validation/WarehouseFormValidation';
import RadioButton from '../../../App/Components/RadioButton/RadioButton';

const WarehouseForm = ({ handleClose, onSubmit, storeID = 0 }) => {
  const { getWarehouseForm, warehouseFormData } = useWarehouse();
  const isMounted = useIsMounted();
  useEffect(() => {
    getWarehouseForm(storeID);
  }, [storeID]);

  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    isValid,
    handleBlur,
    dirty,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...warehouseFormData?.store,
    },
    validationSchema: WarehouseFormValidation,
    onSubmit: async (values, { resetForm }) => {
      await onSubmit(values, resetForm);
      if (isMounted) {
        resetForm();
      }
    },
  });

  return (
    <Modal
      onDrag={true}
      closeHandler={handleClose}
      customClass="L-warehouse-form"
    >
      <form onSubmit={handleSubmit}>
        {warehouseField.map((field) => {
          const { fieldType, label, name, required, type, maxLength } = field;

          const fieldError = touched[name] && errors[name];

          switch (fieldType) {
            case 'input':
              return (
                <div key={name} className="L-warehouse-input">
                  <Input
                    type={type}
                    inputTitle={label}
                    name={name}
                    requiredField={required}
                    onChange={handleChange}
                    value={values[name]}
                    maxLength={maxLength}
                    onBlur={handleBlur}
                    error={fieldError}
                  />
                </div>
              );
            // case 'dropdown':
            //   return (
            //     <div key={index} className={mainClassName}>
            //       <SelectControl
            //         selectBoxTitle={dropdownTitle}
            //         requiredFiled={isRequired}
            //         value={{
            //           label: values[fieldName] || '',
            //           value: values[fieldName] || '',
            //         }}
            //         onChange={(selectedOption) =>
            //           onDropdownChange(fieldName, dropdownId, selectedOption)
            //         }
            //         options={dropDownOptions || []}
            //         onBlur={handleBlur}
            //       />
            //     </div>
            //   );
            // case 'radio':
            //   return (
            //     <div
            //       key={index}
            //       className={`${mainClassName} L-partner-radio-block`}
            //     >
            //       <InputTitlt
            //         requiredFiled={isRequired}
            //         inputTitle={radioTitle}
            //       />
            //       <div className="radio-btn-flex">
            //         {radioBtn?.map(({ value, name, label }, i) => (
            //           <div key={i}>
            //             <RadioButton
            //               name={name}
            //               value={value}
            //               checked={value === values[name]}
            //               onChange={() => setFieldValue(name, value)}
            //               label={label}
            //             />
            //           </div>
            //         ))}
            //       </div>
            //     </div>
            //   );
            default:
              return null;
          }
        })}

        <GetSentDataBtn
          cancelPrintClick={handleClose}
          confirmExecuteClick={handleSubmit}
          executeClass={!dirty || !isValid ? 'G-disabled-button' : ''}
          executeDisabled={!dirty || !isValid}
        />
      </form>
    </Modal>
  );
};

export default WarehouseForm;
