import { createAction } from "../actionCreators";

import {
  DELETE_INVOICING_OPERATIONS,
  GET_INVOICING_OPERATIONS_DATA,
  LOADING,
  UPDATE_SIGN_FILED,
} from './constants';

export const getInvoicingOperationAction = createAction(GET_INVOICING_OPERATIONS_DATA);
export const loadingAction = createAction(LOADING, false);
export const deleteInvoicingOperationAction = createAction(
  DELETE_INVOICING_OPERATIONS,
);

export const updateSignFiledAction = createAction(UPDATE_SIGN_FILED);
