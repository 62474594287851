import React from 'react';
import Alert from '../../../App/Components/Alert';
import useIsMounted from '../../hooks/useIsMounted';
import MaterialNameForm from './MaterialNameForm';
import useMaterialValuesName from '../../hooks/useMaterialValuesName';

const EventAddForm = ({ handleClose, params }) => {
  const { addMaterialName, errorMess, setErrorMess } = useMaterialValuesName();
  const isMounted = useIsMounted();

  const onAddHandler = async (values, resetForm) => {
    const newValues = {
      ...values,
      groupNo: Number(values.groupNo),
    };
    try {
      await addMaterialName(newValues, handleClose, params);
      if (isMounted && !errorMess) {
        resetForm();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <>
      <MaterialNameForm onSubmit={onAddHandler} handleClose={handleClose} />
      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default EventAddForm;
