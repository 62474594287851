import React, { useEffect, useState } from 'react';
import Modal from '../../../App/Components/Modal/Modal';
import { useFormik } from 'formik';
import GetSentDataBtn from '../../../App/Components/GetSentDataBtn/GetSentDataBtn';
import useIsMounted from '../../hooks/useIsMounted';
import useMaterialValuesName from '../../hooks/useMaterialValuesName';
import AdditionalMatNameForm from './AdditionalMatNameForm';
import GeneraleMatNameForm from './GeneraleMatNameForm';
import TabButtons from './TabButtons';
import { MaterialValueNameValidation } from '../../../App/Validation/MaterialValueNameValidation';

const MaterialNameForm = ({ handleClose, onSubmit, matValueID = 0 }) => {
  const [activeTab, setActiveTab] = useState('GeneraleMatNameForm');

  const { getMaterialNameForm, materialNameFormData, getMaterialNameAccount } =
    useMaterialValuesName();
  const isMounted = useIsMounted();

  useEffect(() => {
    getMaterialNameForm(matValueID);
  }, [matValueID]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    isValid,
    handleBlur,
    dirty,
    setFieldValue,
  } = useFormik({
    mode: 'onBlur',
    enableReinitialize: true,
    initialValues: {
      ...materialNameFormData?.matValue,
    },
    validationSchema: MaterialValueNameValidation,
    onSubmit: async (values, { resetForm }) => {
      await onSubmit(values, resetForm);
      if (isMounted) {
        resetForm();
      }
    },
  });
  const generateTabComponent = (Component, props) => <Component {...props} />;
  const commonProps = {
    materialNameFormData,
    getMaterialNameAccount,
    values,
    onChange: handleChange,
    setFieldValue,
    errors,
    touched,
    handleBlur,
  };

  const tabComponents = {
    GeneraleMatNameForm: generateTabComponent(GeneraleMatNameForm, commonProps),
    AdditionalMatNameForm: generateTabComponent(
      AdditionalMatNameForm,
      commonProps,
    ),
  };

  return (
    <Modal
      onDrag={true}
      closeHandler={handleClose}
      customClass="L-material-values-names-form"
      // title={title}
    >
      <TabButtons activeTab={activeTab} handleTabChange={handleTabChange} />

      <div className="tab-content">{tabComponents[activeTab]}</div>
      <GetSentDataBtn
        customClassForBlock="G-salary-btn-block"
        cancelPrintClick={handleClose}
        confirmExecuteClick={handleSubmit}
        executeClass={!dirty || !isValid ? 'G-disabled-button' : ''}
        executeDisabled={!dirty || !isValid}
      />
    </Modal>
  );
};

export default MaterialNameForm;
