import React from 'react';
import { getRequest } from '../../../Api/Api';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import Modal from '../../../Components/Modal/Modal';
import Table from '../../../Components/Table/Table';
import './OperationByRows.scss';
import { prodServWorkNameList } from '../ProdServWorkNameList';

const OperationByRows = ({ excelInvoiceID, closeModal }) => {
  const closeHandler = () => closeModal(false);
  const [operationByRowData, setOperationByRowData] = useState([]);
  const getData = useCallback(async () => {
    //    dispatch(loadingAction(true));
    await getRequest(`getExcelInvoiceGoods?excelInvoiceID=${excelInvoiceID}`)
      .then((res) => {
        setOperationByRowData(res.data);
      })
      .catch((err) => {
        console.log('Err' + err);
      });
    //  .finally(() => {
    //    dispatch(loadingAction(false));
    //  });
  }, [excelInvoiceID]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Modal
      closeHandler={closeHandler}
      onDrag={true}
      customClass="L-operation-by-rows-modal"
    >
      <div className="G-modal-block-title">
        <h3>Ապրանքի, ծառայության,աշխատանքի անվանում </h3>
      </div>
      {operationByRowData !== null &&
      operationByRowData &&
      operationByRowData?.length ? (
        <Table
          customClass="L-operation-by-rows-table"
          data={operationByRowData}
          columnConfig={prodServWorkNameList}
        />
      ) : (
        ''
      )}
    </Modal>
  );
};

export default OperationByRows;
