import React from 'react';
import './radioButton.scss';

const RadioButton = (props) => {
  const {
    id,
    onChange,
    value,
    checked,
    label,
    name,
    defaultChecked,
    disabled,
    onBlur,
  } = props;
  return (
    <div className="G-radio-button">
      <input
        defaultChecked={defaultChecked}
        id={id}
        name={name}
        onChange={onChange}
        value={value}
        type="radio"
        checked={checked}
        disabled={disabled}
        onBlur={onBlur}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default RadioButton;
