import React, { useEffect, useState } from "react";
import { postRequest } from "../../../Api/Api";
import Calendar from "../../../Components/Calendar/Calendar";
import GetSentDataBtn from "../../../Components/GetSentDataBtn/GetSentDataBtn";
import InputFiled from "../../../Components/InputFiled/InputFiled";
import Modal from "../../../Components/Modal/Modal";
import NumericFormatFiled from '../../../Components/NumericFormat/NumericFormatFiled';
import SelectAccount from "../../../Components/SelectBox/SelectAccount";
import {
  dataTimeFormat,
  initialDate,
  removeCommas,
} from '../../../Utilities/Utilities';

const GetBankEntryOrders = ({
  closeModal,
  paymentData,
  excelInvoiceID,
  setErrorHandler,
}) => {
  const handleClose = () => closeModal(false);
  const { partnerName, description, docNum } = paymentData || [];
  const mainAmount = paymentData?.amount;
  const [amount, setAmount] = useState(mainAmount);
  const [initialDocNum, setInitialDocNum] = useState(docNum);
  const [value, setValue] = useState({});
  const [docDate, setDocDate] = useState(initialDate);
  const formatDocDate = dataTimeFormat(docDate);
  const [debitAccountVal, setDebitAccountVal] = useState({
    label: '',
    value: '',
    id: '',
  });

  const [creditAccountVal, setCreditAccountVal] = useState({
    label: '',
    value: '',
    id: '',
  });

  useEffect(() => {
    setAmount(mainAmount);
    setInitialDocNum(docNum);
  }, [mainAmount, docNum]);

  useEffect(() => {
    const addValueObj = {
      excelInvoiceID: excelInvoiceID,
      docDate: formatDocDate,
      docNum: initialDocNum,
      account: debitAccountVal.value,
      amount: removeCommas(amount),
      accountCredit: creditAccountVal.value,
    };
    setValue({ ...value, ...addValueObj });
  }, [
    amount,
    creditAccountVal.value,
    debitAccountVal.value,
    excelInvoiceID,
    formatDocDate,
    initialDocNum,
  ]);

  const disableBtn = (val) => {
    if (val.account === '') {
      return true;
    }
    if (val.accountCredit === '') {
      return true;
    }

    if (val.docNum === '') {
      return true;
    }
    if (removeCommas(val.amount) > mainAmount || val.amount === '') {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = () => {
    postRequest('saveInvoiceBankOrder', value)
      .then((res) => {
        if (res.data.errorCode === 0) {
          handleClose();
        } else if (res.data.errorCode > 0) {
          setErrorHandler(res.data.message);
        }
      })
      .catch((err) => {
        console.log('err' + err);
      });
  };

  const debitCreditChangeHandler = (value, name) => {
    if (!value) {
      value = {
        value: '',
      };
    }

    if (name == 'debitAcc') {
      setDebitAccountVal(value);
    }
    if (name == 'creditAcc') {
      setCreditAccountVal(value);
    }
  };
  return (
    <Modal
      closeHandler={handleClose}
      customClass="L-bank-entry-order-modal"
      onDrag={true}
    >
      <div className="L-bank-entry-order-modal-content">
        <div className="G-modal-block-title">
          <h3>Բանկի Մուտքի օրդեր</h3>
        </div>
        <div className="G-form-data-picker">
          <Calendar
            selected={docDate}
            changeHandler={(e) => setDocDate(e, 'docDate')}
            name="docDate"
          />
        </div>
        <div className="L-bank-entry-wrapper G-docNum-input-not-border">
          <InputFiled
            inputTitle="Փաստաթուղթ N"
            value={initialDocNum}
            name="docNum"
            changeHandler={(e) => setInitialDocNum(e.target.value)}
          />
        </div>
        <div className="L-bank-entry-wrapper">
          <p className="G-title-p">Գործընկեր</p>
          <p>{partnerName}</p>
        </div>

        <SelectAccount
          customClass="L-bank-entry-wrapper"
          title="Դեբետ հաշիվ"
          accountData={paymentData?.debetAccounts}
          accountVal={debitAccountVal}
          selectChangeAccount={(value) =>
            debitCreditChangeHandler(value, 'debitAcc')
          }
        />

        <SelectAccount
          customClass="L-bank-entry-wrapper"
          title="Կրեդիտ հաշիվ"
          accountData={paymentData?.creditAccounts}
          accountVal={creditAccountVal}
          selectChangeAccount={(value) =>
            debitCreditChangeHandler(value, 'creditAcc')
          }
        />

        <div className="G-amount-input">
          <NumericFormatFiled
            value={amount}
            name="amount"
            changeHandler={(e) => setAmount(e.target.value)}
            inputTitle="Գումարը"
            requiredFiled={true}
          />
        </div>
        <div className="L-bank-entry-wrapper">
          <p className="G-title-p">Նշումներ</p>
          <p>{description}</p>
        </div>

        <GetSentDataBtn
          cancelPrintClick={handleClose}
          confirmExecuteClick={handleSubmit}
          executeClass={disableBtn ? 'G-disabled-button' : ''}
          executeDisabled={disableBtn(value)}
        />
      </div>
    </Modal>
  );
};

export default GetBankEntryOrders;
