import { useEffect, useState } from 'react';
import {
  fetchAccountingFormulations,
  saveAccountingFormulation,
  removeAccountingFormulation,
  fetchDateOfFormation,
  fetchSalaryMonths,
  fetchNewFormulations,
} from '../services/fetchAccountingFormulations';
import { useSelector } from 'react-redux';

const useAccountingFormulations = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [error, setError] = useState('');
  const [errorMess, setErrorMess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [accountFormulationData, setAccountFormulationData] = useState(null);
  const [formulationsTable, setFormulationsTable] = useState([]);
  const [salaryMonthData, setSalaryMonthData] = useState(null);

  const getAccountingFormulations = async () => {
    setIsLoading(true);
    try {
      const data = await fetchAccountingFormulations(currentCompanyID);
      setAccountFormulationData(data);
    } catch (error) {
      setError('Error fetching data');
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };
  const getSalaryMonths = async (year) => {
    setIsLoading(true);
    try {
      const data = await fetchSalaryMonths(currentCompanyID, year);

      setSalaryMonthData(data);
    } catch (error) {
      setError('Error fetching data');
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getDateOfFormation = async (query) => {
    setIsLoading(true);
    try {
      const data = await fetchDateOfFormation(currentCompanyID, query);
      if (data) {
        setFormulationsTable((prev) => ({
          ...prev,
          ...data,
          statements: data.statements,
        }));
      }
    } catch (error) {
      setError('Error fetching data');
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getNewFormulations = async () => {
    setIsLoading(true);
    try {
      const data = await fetchNewFormulations(currentCompanyID);
      setFormulationsTable(data);
    } catch (error) {
      console.log('error', error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const saveAccountingFormulations = async (accountingFormulation, query) => {
    try {
      const { errorCode, message } = await saveAccountingFormulation(
        accountingFormulation?.statements,
      );

      if (errorCode === 0) {
        getDateOfFormation(query);
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    }
  };

  const deleteAccountingFormulation = async (salaryDate) => {
    setIsLoading(true);
    try {
      const { errorCode, message } = await removeAccountingFormulation(
        currentCompanyID,
        salaryDate,
      );

      if (errorCode === 0) {
        setFormulationsTable([]);
      }
      if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    return () => {
      setError('');
      setErrorMess('');
      setIsLoading(false);
    };
  }, []);

  return {
    getAccountingFormulations,
    accountFormulationData,
    getNewFormulations,
    formulationsTable,
    saveAccountingFormulations,
    deleteAccountingFormulation,
    setFormulationsTable,
    getDateOfFormation,
    getSalaryMonths,
    salaryMonthData,
    isLoading,
    error,
    errorMess,
    setErrorMess,
  };
};

export default useAccountingFormulations;
