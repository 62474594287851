import React, { useState } from 'react';
import { useFormik } from 'formik';
import Modal from '../../Components/Modal/Modal';
import InputFiled from '../../Components/InputFiled/InputFiled';
import ErrorFiled from '../../Components/ErrorFiled/ErrorFiled';
import Checkbox from '../../Components/Checkbox/Checkbox';
import GetSentDataBtn from '../../Components/GetSentDataBtn/GetSentDataBtn';
import { PartnerFormValidation } from '../../Validation/PartnerFormValidation';
import RadioButton from '../../Components/RadioButton/RadioButton';
import SelectPartnerType from '../../Components/SelectBox/SelectPartnerType';
import { default as OpenPartnerBankForm } from './PartnerBank/EventAddForm';
import IsAddBank from './IsAddBank';
import { textFiledLength } from '../../Utilities/Utilities';

const PartnerForm = ({
  initialData,
  closeModal,
  onSubmit,
  partnerFormTitle,
  partnerID,
  partnerName,
  currentPathname,
}) => {
  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    setFieldValue,
    isValid,
    handleBlur,
    dirty,
  } = useFormik({
    mode: 'onBlur',
    enableReinitialize: true,
    initialValues: { ...initialData },
    validationSchema: PartnerFormValidation,
    onSubmit: (values, { resetForm }) => onSubmit(values, resetForm),
  });

  const [openPartnerBankForm, setOpenPartnerBankForm] = useState(false);
  const [partnerBankInfo, setPartnerBankInfo] = useState({});
  const handleChangePartnerType = (values) => {
    if (!values) {
      values = {
        value: '',
      };
    }

    setFieldValue('partnerType', values.id);
    setFieldValue('partnerTypeName', values.value);
  };

  const onConfirmBank = () => {
    setOpenPartnerBankForm(partnerID);
  };

  const getPartnerFormTitle = () => {
    let result = '';

    const commonStyle = { fontSize: '14px' };

    if (Object.keys(partnerBankInfo).length) {
      const { bankingAccount, bankName } = partnerBankInfo;
      const cardNumber = bankName.match(/\d+/)?.[0] || '';
      const symbols = bankName.slice(cardNumber.length).trim();

      result = (
        <h3 style={commonStyle}>
          {`${bankingAccount} ${symbols} բանկը մուտքագրվեց`}
        </h3>
      );
    } else if (partnerName) {
      result = (
        <h3 style={commonStyle}>
          <strong className="strong">{partnerName}</strong>
          {partnerFormTitle}
        </h3>
      );
    } else {
      result = <h3 style={commonStyle}>{partnerFormTitle}</h3>;
    }

    return result;
  };

  return (
    <Modal closeHandler={closeModal} onDrag={true} customClass="L-partner-form">
      <div className="L-partner-form-content">
        <div className="G-modal-block-title" style={{ width: '97%' }}>
          {getPartnerFormTitle()}
        </div>
        <form onSubmit={onSubmit}>
          {!partnerID && (
            <>
              <div className="L-partner-wrapper">
                <InputFiled
                  inputTitle="Գործընկերոջ անվանումը"
                  requiredFiled={true}
                  name="partnerName"
                  value={values.partnerName}
                  changeHandler={handleChange}
                  onBlur={handleBlur}
                  maxLength={50}
                />
                {touched.partnerName && errors.partnerName && (
                  <ErrorFiled error={errors.partnerName} />
                )}
              </div>
              <div className="L-partner-wrapper">
                <SelectPartnerType
                  isClearable={true}
                  selectBoxTitle="Տեսակը"
                  matchFromParam=""
                  requiredFiled={true}
                  onBlur={handleBlur}
                  partnerTypeVal={{
                    label: values.partnerTypeName,
                    value: values.partnerTypeName,
                  }}
                  selectChangePartner={(values) =>
                    handleChangePartnerType(values)
                  }
                />
                {touched.partnerType && errors.partnerType && (
                  <ErrorFiled error={errors.partnerType} />
                )}
              </div>

              <div className="L-partner-wrapper">
                <p className="G-title-p">Տիպը</p>
                <Checkbox
                  checked={values.partnerForm}
                  label="ֆիզիկական անձ"
                  name="partnerForm"
                  value={values.partnerForm}
                  onChange={() =>
                    setFieldValue('partnerForm', !values.partnerForm)
                  }
                />
              </div>

              <div className="L-partner-bank-type G-flex">
                <RadioButton
                  value={values.countryType}
                  checked={values.countryType === 0}
                  id="countryType"
                  name="countryType"
                  label="ՀՀ"
                  onChange={() => setFieldValue('countryType', 0)}
                />
                <RadioButton
                  value={values.countryType}
                  checked={values.countryType === 1}
                  id="countryType"
                  name="countryType"
                  label="Արտերկիր"
                  onChange={() => setFieldValue('countryType', 1)}
                />
              </div>

              <div className="L-partner-wrapper">
                <InputFiled
                  inputTitle="ՀՎՀՀ"
                  name="taxCode"
                  value={values.taxCode}
                  changeHandler={handleChange}
                  onBlur={handleBlur}
                  requiredFiled={
                    !values.partnerForm && values.partnerType !== 4
                  }
                  maxLength={values.countryType === 0 ? 8 : 20}
                />
                {touched.taxCode && errors.taxCode && (
                  <ErrorFiled error={errors.taxCode} />
                )}
              </div>

              <div className="L-physical-person-flex G-flex">
                <div className="L-partner-wrapper">
                  <InputFiled
                    inputTitle="ՀԾՀ"
                    name="taxeServiceNumber"
                    value={values.taxeServiceNumber}
                    changeHandler={handleChange}
                    onBlur={handleBlur}
                    requiredFiled={
                      values.partnerType == 4 && !values.partnerForm
                    }
                    maxLength={values.countryType === 0 ? 10 : 20}
                  />
                  {touched.taxeServiceNumber && errors.taxeServiceNumber && (
                    <ErrorFiled error={errors.taxeServiceNumber} />
                  )}
                </div>
                <div className="L-partner-wrapper">
                  <InputFiled
                    inputTitle="Անձնագիր"
                    name="passport"
                    value={values.passport}
                    changeHandler={handleChange}
                    onBlur={handleBlur}
                    maxLength={values.countryType === 0 ? 9 : 20}
                  />
                  {touched.passport && errors.passport && (
                    <ErrorFiled error={errors.passport} />
                  )}
                </div>
                <div className="L-partner-wrapper">
                  <InputFiled
                    inputTitle="ID քարտ"
                    name="idCard"
                    value={values.idCard}
                    changeHandler={handleChange}
                    onBlur={handleBlur}
                    maxLength={values.countryType === 0 ? 9 : 20}
                  />
                  {touched.idCard && errors.idCard && (
                    <ErrorFiled error={errors.idCard} />
                  )}
                </div>
              </div>
              {values.partnerForm && (
                <p
                  style={{
                    color: 'red',
                    fontSize: '12px',
                    textAlign: 'center',
                    marginTop: '6px',
                  }}
                >
                  {errors.all}
                </p>
              )}
              <div className="L-partner-wrapper">
                <InputFiled
                  inputTitle="Հիմքը (Պայմանագիր N կամ փաստաթուղթ N )"
                  name="basis"
                  value={values.basis}
                  changeHandler={handleChange}
                  onBlur={handleBlur}
                  maxLength={textFiledLength}
                />
              </div>

              <div className="L-partner-wrapper">
                <InputFiled
                  inputTitle="Հասցեն"
                  name="description"
                  value={values.description}
                  changeHandler={handleChange}
                  onBlur={handleBlur}
                  maxLength={textFiledLength}
                />
              </div>
            </>
          )}

          <div className="G-flex-align-center" style={{ width: '100%' }}>
            {!partnerID && (
              <GetSentDataBtn
                cancelPrintClick={closeModal}
                confirmExecuteClick={handleSubmit}
                executeClass={!isValid || !dirty ? 'G-disabled-button' : ''}
                executeDisabled={!isValid || !dirty}
              />
            )}
          </div>
        </form>
      </div>

      {currentPathname && partnerID && (
        <IsAddBank closeHandler={closeModal} confirmBank={onConfirmBank} />
      )}

      {openPartnerBankForm && (
        <OpenPartnerBankForm
          closeModal={setOpenPartnerBankForm}
          partnerData={{ partnerID: openPartnerBankForm }}
          addNewPartnerBank={true}
          partnerBankInfo={setPartnerBankInfo}
        />
      )}
    </Modal>
  );
};

export default PartnerForm;
