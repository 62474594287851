import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import Modal from '../../../App/Components/Modal/Modal';
import { partnerFormFields } from './FieldsConfig';
import { SelectControl } from '../../../App/Components/SelectControl/SelectControl';
import Checkbox from '../../../App/Components/Checkbox/Checkbox';
import InputTitle from '../../components/Input/InputTitle';
import Input from '../../components/Input/Input';
import RadioButton from '../../../App/Components/RadioButton/RadioButton';
import { CANCEL, CONFIRM } from '../../constant';
import Button from '../../../App/Components/Button/Button';
import usePartner from '../../hooks/usePartner';
import { PartnerFormValidation } from '../../../App/Validation/PartnerFormValidation';
const PartnersForm = ({ handleClose, onSubmit, partnerValues }) => {
  const { getPartnerTypes, partnerTypes } = usePartner();

  useEffect(() => {
    getPartnerTypes();
  }, []);

  let dropDownOptions = [];
  const mainClassName = 'L-material-values-partner-filed';

  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    isValid,
    handleBlur,
    dirty,
    setFieldValue,
  } = useFormik({
    mode: 'onBlur',
    enableReinitialize: true,
    initialValues: partnerValues,
    validationSchema: PartnerFormValidation,
    onSubmit: async (values, { resetForm }) => {
      await onSubmit(values, resetForm);
    },
  });
  const isButtonDisabled = !dirty || !isValid;

  const onDropdownChange = (fieldName, dropdownId, selectedOption) => {
    if (!selectedOption) {
      selectedOption = {
        value: '',
        id: '',
      };
    }
    const fieldValue = selectedOption.value;
    const dropdownItemId = selectedOption.id;

    setFieldValue(fieldName, fieldValue);
    setFieldValue(dropdownId, dropdownItemId);
  };

  const handleButtonClick = (config) => {
    const { onClickHandler, actionType } = config || {};

    if (typeof onClickHandler === 'function') {
      switch (actionType) {
        case CANCEL:
          handleClose();
          break;
        case CONFIRM:
          onSubmit(values);
          break;
        default:
          break;
      }
    }
  };

  return (
    <Modal
      onDrag={true}
      closeHandler={handleClose}
      customClass="L-material-values-partner-form"
    >
      <form onSubmit={handleSubmit}>
        {partnerFormFields.map((field, index) => {
          const {
            fieldType,
            fieldName,
            label,
            name,
            required,
            Children,
            optionName,
            dropdownTitle,
            type,
            dropdownId,
            radioBtn,
            radioTitle,
            maxLength,
            personalData,
            checkboxTitle,
          } = field;
          const isRequired =
            typeof required === 'function' ? required(values) : required;
          const isLength =
            typeof maxLength === 'function' ? maxLength(values) : maxLength;
          const fieldError = touched[name] && errors[name];

          if (typeof Children === 'function' && partnerTypes) {
            dropDownOptions = Children(partnerTypes[optionName]);
          }
          switch (fieldType) {
            case 'input':
              return (
                <div key={index} className={mainClassName}>
                  <Input
                    type={type}
                    inputTitle={label}
                    name={name}
                    requiredField={isRequired}
                    onChange={handleChange}
                    value={values[name]}
                    maxLength={isLength}
                    error={fieldError}
                    onBlur={handleBlur}
                  />
                </div>
              );
            case 'dropdown':
              return (
                <div key={index} className={mainClassName}>
                  <SelectControl
                    selectBoxTitle={dropdownTitle}
                    requiredFiled={isRequired}
                    value={{
                      label: values[fieldName] || '',
                      value: values[fieldName] || '',
                    }}
                    onChange={(selectedOption) =>
                      onDropdownChange(fieldName, dropdownId, selectedOption)
                    }
                    options={dropDownOptions || []}
                    onBlur={handleBlur}
                  />
                </div>
              );
            case 'checkbox':
              return (
                <div key={index} className={`${mainClassName} `}>
                  <InputTitle
                    requiredFiled={isRequired}
                    inputTitle={checkboxTitle}
                  />
                  <Checkbox
                    name={name}
                    label={label}
                    value={values[name]}
                    checked={values[name]}
                    onChange={() => setFieldValue(name, !values[name])}
                    onBlur={handleBlur}
                  />
                </div>
              );
            case 'radio':
              return (
                <div
                  key={index}
                  className={`${mainClassName} L-partner-radio-block`}
                >
                  <InputTitle
                    requiredFiled={isRequired}
                    inputTitle={radioTitle}
                  />
                  <div className="radio-btn-flex">
                    {radioBtn?.map(({ value, name, label }, i) => (
                      <div key={i}>
                        <RadioButton
                          name={name}
                          value={value}
                          checked={value === values[name]}
                          onChange={() => setFieldValue(name, value)}
                          label={label}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              );
            case 'personalData':
              return (
                <div
                  key={index}
                  className={`${mainClassName} L-partner-personal-data`}
                >
                  {personalData?.map(({ name, label, maxLength }) => (
                    <div className="L-partner-personal-filed" key={name}>
                      <Input
                        type={type}
                        inputTitle={label}
                        name={name}
                        requiredFiled={false}
                        onChange={handleChange}
                        value={values[name]}
                        maxLength={
                          typeof maxLength === 'function'
                            ? maxLength(values)
                            : maxLength
                        }
                      />
                    </div>
                  ))}
                </div>
              );
            case 'button':
              return (
                <div
                  key={index}
                  className={`${mainClassName} G-confirm-cancel-btn`}
                >
                  {personalData?.map((config, ind) => (
                    <div key={ind} className="G-medium-btn">
                      <Button
                        type="button"
                        text={config.buttonText}
                        onClick={() => handleButtonClick(config)}
                        disabled={
                          typeof config.disabled === 'function'
                            ? config.disabled(isButtonDisabled)
                            : false
                        }
                        customClass={
                          isButtonDisabled ? 'G-disabled-button' : ''
                        }
                      />
                    </div>
                  ))}
                </div>
              );
            default:
              return null;
          }
        })}
      </form>
    </Modal>
  );
};

export default PartnersForm;
