import dashboard from '../Assets/menuIcons/dashboard.svg';
import data from '../Assets/menuIcons/data.svg';
import informants from '../Assets/menuIcons/Informants.svg';
import documentEntry from '../Assets/menuIcons/documentEntry.svg';
import operations from '../Assets/menuIcons/operations.svg';
import reports from '../Assets/menuIcons/reports.svg';
import help from '../Assets/menuIcons/help.svg';

export const routing = [
  {
    path: '/Dashboard',
    name: 'Վահանակ',
    icon: dashboard,
  },

  {
    path: '#',
    name: 'Տվյալներ',
    icon: data,
    chillers: [
      {
        path: 'Organization',
        name: 'Իմ կազմակերպությունը',
      },
      {
        path: 'CompanyBank',
        name: 'Իմ բանկերը',
      },

      {
        path: '/Partners',
        name: 'Իմ գործընկերները',
      },
      {
        path: '/InitialBalances',
        name: 'Իմ հաշիվների սկզբնական մնացորդները',
      },
    ],
  },

  {
    path: '#',
    name: 'Տեղեկատուներ',
    icon: informants,
    chillers: [
      {
        path: '/ChartOfAccounts',
        name: 'Հաշվային պլան',
      },
      {
        path: '/AccountCorrespondence',
        name: 'Հաշվային թղթակցություններ',
      },
      {
        path: '/Banks',
        name: 'ՀՀ բանկեր',
      },

      {
        path: '/ServiceWork',
        name: 'Ծառայություն / աշխատանք',
      },
      {
        path: '/Product',
        name: 'Ապրանքներ',
      },
      {
        path: '/UnitOfMeasure',
        name: 'Չափման միավորներ',
      },

      {
        path: 'Currencies',
        name: 'Արժույթներ',
      },
    ],
  },

  {
    path: '#',
    name: 'Փաստաթղթերի մուտքագրում',
    icon: documentEntry,
    chillers: [
      {
        path: '/CashWarrant',
        name: 'Դրամարկղի մուտքի օրդեր',
      },

      {
        path: '/CashWithdrawalOrder',
        name: 'Դրամարկղի ելքի օրդեր',
      },
      {
        path: '/PaymentOrder',
        name: 'Վճարման հանձնարարագիր',
      },
      {
        path: '/ActOfPurchase',
        name: 'Գնման ակտ',
      },

      {
        path: '/PrepaidTransferAccount',
        name: 'Կանխավճարային/փոխանցման հաշիվ',
      },
      {
        path: '/AccountForSpendingMoney',
        name: 'Հաշվետվություն առհաշիվ գումարների ծախսման մասին',
      },
    ],
  },
  {
    path: '#',
    name: 'Գործողություններ',
    icon: operations,
    chillers: [
      {
        path: '/DownloadEInvoicingAccounts',
        name: 'E-Invoicing հաշիվների արտածում',
      },
      {
        path: '/E-InvoicingOperations',
        name: 'E-Invoicing գործառնություններ',
      },
      {
        path: '/Operations',
        name: 'Գործառնություններ',
      },

      {
        path: '/ClosingAccountBalances',
        name: 'Եկամուտ/ծախս հաշիվների մնացորդների փակում',
      },
      {
        path: '/FormationOfNewInitialBalances',
        name: 'Նոր սկզբնական մնացորդների ձևավորում',
      },
    ],
  },
  {
    path: '#',
    name: 'Հաշվետվություններ',
    icon: reports,
    chillers: [
      {
        path: '/Documents',
        name: 'Փաստաթղթեր',
      },
      {
        path: '/BalanceBook',
        name: 'Մնացորդների մատյան',
      },
      {
        path: '/Obtaining_three_digit_account_balances',
        name: 'Եռանիշ հաշիվների մնացորդների ստացում',
      },
      {
        path: '/CirculationNewsletter',
        name: 'Շրջանառության տեղեկագիր',
      },
      {
        path: '/AccountStatement',
        name: 'T – Հաշիվ (քաղվածք հաշվից)',
      },
      {
        path: '/AccountAnalysis',
        name: 'T – Հաշիվ (հաշվի վերլուծություն)',
      },
      {
        path: '/TransactionLog',
        name: 'Գործառնությունների մատյան',
      },
      {
        path: '/PartnerBalanceBook',
        name: 'Գործընկերների մնացորդների մատյան',
      },
      {
        path: '/PartnerCirculationBulletin',
        name: 'Գործընկերների շրջանառության տեղեկագիր',
      },
      {
        path: '/PartnersTAccountAccountStatement',
        name: 'Գործընկերների T – հաշիվ (քաղվածք հաշվից)',
      },
      {
        path: '/SettlementDocuments',
        name: 'Հաշվարկային փաստաթղթերի մատյան (e-invoicing)',
      },
    ],
  },

  {
    path: '/WorkGuide',
    name: 'Աշխատանքի ուղեցույց',
    icon: help,
  },
];
