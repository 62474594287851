export const queryValue = (somValue) => {
  const entries = Object.entries(somValue).filter(([key, value]) => value);
  const somParams = entries.length > 0 ? Object.fromEntries(entries) : null;
  return somParams;
};

export const queryString = (params) => {
  return new URLSearchParams(params).toString();
};

// export const urlEncodeQueryParams = (data) => {
//   const params = Object?.keys(data)?.map((key) => {
//     const value = data[key];
//     //? data[key]?.trim() : data[key]
//     return value
//       ? `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
//       : '';
//   });
//   return params.filter((value) => !!value).join('&');
// };

export const urlEncodeQueryParams = (data) => {
  const params = Object.keys(data).map((key) =>
    data[key]
      ? `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
      : '',
  );
  return params.filter((value) => !!value).join('&');
};
