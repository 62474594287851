import { deleteRequest, getRequest, postRequest } from '../../App/Api/Api';
import { urlEncodeQueryParams } from '../../App/Helper/urlHelper';
export const fetchConfirmPositions = async (companyID) => {
  try {
    const response = await getRequest(
      `getConfirmSalary?companyID=${companyID}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const fetchConfirm = async (companyID, query) => {
  const queryRoot = urlEncodeQueryParams(query);
  try {
    const response = await getRequest(
      `getEmployeesForConfirm?companyID=${companyID}&${queryRoot}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const addConfirmPositions = async (data) => {
  try {
    const response = await postRequest('saveSalaries ', data);

    return response.data;
  } catch (error) {
    console.error('Error adding ConfirmPositions:', error);
    throw error;
  }
};

export const fetchBaselineState = async (companyID, stNo) => {
  try {
    const response = await deleteRequest(
      `deleteConfirmSalaryEntries?companyID=${companyID}&stNo=${stNo}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};
