import React from 'react';
import SalaryCalculator from './salaryCalculator/SalaryCalculator';
import EmployeesList from './employeesList/EmployeesList';

const EmployeesDashboard = () => {
  return (
    <div className="L-employees-dashboard-block">
      <SalaryCalculator />
      <EmployeesList />
    </div>
  );
};

export default EmployeesDashboard;
