import React, { useEffect } from 'react';
import { useState } from 'react';
import Modal from '../../../Components/Modal/Modal';
import Table from '../../../Components/Table/Table';
import { useFetch } from '../../../Hooks/useFetch';
import { Commas, dateFormat } from '../../../Utilities/Utilities';

const GetPurchaseActOperations = ({ closeModal, purchaseID }) => {
  const handleClose = () => closeModal(false);
  const [data] = useFetch(`getPurchaseEntries?purchaseID=${purchaseID} `);
  const [generalData, setGeneralData] = useState(data);

  useEffect(() => {
    setGeneralData(data);
  }, [data]);

  // const filterData = (entryID) => {
  //   deleteRequest(`deletePurchaseEntry/${entryID}`).then((res) => {
  //     if (res.data.errorCode === 0) {
  //       const fitterArr = data?.filter((item) => {
  //         return item.entryID !== entryID;
  //       });
  //       setGeneralData(fitterArr);
  //     }
  //   });
  // };

  const columnConfig = [
    {
      title: 'Ամսաթիվ',
      cell: (row) => dateFormat(row.docDate),
      customStyle: { maxWidth: 70 },
    },
    {
      title: 'Փաստաթղթի Տեսակը',
      cell: (row) => row.docTypeName,
      customStyle: { maxWidth: 360 },
    },
    {
      title: 'Փաստաթուղթ N',
      cell: (row) => row.docNum,
      customStyle: { maxWidth: 120 },
    },
    {
      title: 'Գործընկեր',
      cell: (row) => row.partnerName,
      customStyle: { maxWidth: 500, overflow: 'hidden' },
    },
    {
      title: 'Դեբետ',
      cell: (row) => row.accountDebit,
      customStyle: { maxWidth: 80 },
    },
    {
      title: 'Կրեդիտ',
      cell: (row) => row.accountCredit,
      customStyle: { maxWidth: 80 },
    },
    {
      title: 'Գումար',
      cell: (row) => Commas(row.amount),
      customStyle: { maxWidth: 100 },
    },

    // {
    //   title: 'Գործողություն',
    //   cell: (row) => (
    //     <span className="G-delete-span" onClick={() => filterData(row.entryID)}>
    //       Ջնջել
    //     </span>
    //   ),
    //   customStyle: { maxWidth: 100 },
    // },
  ];
  return (
    <Modal
      closeHandler={handleClose}
      customClass="L-purchase-act-operations-block"
      onDrag={true}
    >
      <div className="L-purchase-act-operations-content">
        <div className="G-modal-block-title">
          <h3>Գնման ակտի գործառնություններ</h3>
        </div>

        {generalData !== null && generalData && generalData?.length ? (
          <Table
            customClass="L-purchase-act-operation-table"
            data={generalData}
            columnConfig={columnConfig}
          />
        ) : (
          ''
        )}
      </div>
    </Modal>
  );
};;

export default GetPurchaseActOperations;
