import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewMaterialName,
  fetchMaterialNameAccount,
  fetchMaterialNameExcelData,
  fetchMaterialNameForm,
  fetchMaterialValuesNames,
  removeCurrentMaterialName,
  updateCurrentMaterialName,
} from '../services/fetchMaterialValuesNames';
import {
  deleteMaterialValNameAction,
  fetchMaterialValNameAction,
  updateMaterialValNameAction,
} from '../../App/Redux/materialValuesStor/materialValuesName/useAction';

const useMaterialValuesName = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [errorMess, setErrorMess] = useState('');
  const materialValueName = useSelector(
    (state) => state.materialValueName?.materialValNameData,
  );
  const [materialNameFormData, setMaterialNameFormData] = useState(null);

  const dispatch = useDispatch();

  const getMaterialValuesNames = async (params) => {
    setIsLoading(true);
    try {
      const data = await fetchMaterialValuesNames(currentCompanyID, params);
      dispatch(fetchMaterialValNameAction(data));
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getMaterialNameForm = async (matValueID) => {
    setIsLoading(true);
    try {
      const data = await fetchMaterialNameForm(currentCompanyID, matValueID);
      setMaterialNameFormData(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  const getMaterialNameAccount = async (groupNo) => {
    setIsLoading(true);

    try {
      const data = await fetchMaterialNameAccount(currentCompanyID, groupNo);

      return data;
    } catch (error) {
      setError(error);
      console.error('Error fetching material name account:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const addMaterialName = async (newMathValue, closeFormCallback, param) => {
    try {
      const { errorCode, message } = await addNewMaterialName(newMathValue);
      if (errorCode === 0) {
        getMaterialValuesNames(param);
        closeFormCallback();
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    }
  };

  const updateMaterialName = async (updateMaterialName, closeFormCallback) => {
    try {
      const { errorCode, message } = await updateCurrentMaterialName(
        updateMaterialName,
      );
      if (message) {
        setErrorMess(message);
      } else if (errorCode === 0) {
        dispatch(updateMaterialValNameAction({ ...updateMaterialName }));
        closeFormCallback();
      }
    } catch (error) {
      setError(error);
    } finally {
    }
  };

  const removeMaterialName = async (matValueID, closeFormCallback) => {
    try {
      const { errorCode, message } = await removeCurrentMaterialName(
        matValueID,
      );

      if (errorCode === 0) {
        dispatch(deleteMaterialValNameAction(matValueID));
        closeFormCallback();
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    } finally {
    }
  };

  const getMaterialNameExcelData = async (params) => {
    setIsLoading(true);
    try {
      const data = await fetchMaterialNameExcelData(currentCompanyID, params);
      return data;
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    return () => {
      setError('');
      setErrorMess('');
    };
  }, []);

  return {
    error,
    isLoading,
    errorMess,
    setErrorMess,
    getMaterialValuesNames,
    materialValueName,
    getMaterialNameForm,
    materialNameFormData,
    addMaterialName,
    updateMaterialName,
    removeMaterialName,
    getMaterialNameExcelData,
    getMaterialNameAccount,
  };
};

export default useMaterialValuesName;
