import React from "react";
import "./button.scss";

const Button = (props) => {
  const {
    text = 'Ավելացնել',
    customClass = '',
    onClick,
    disabled,
    type = 'submit',
  } = props;
  return (
    <button
      className={`G-button ${customClass}`}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      <p>{text}</p>
    </button>
  );
};

export default Button;
