import React from 'react';
import SearchBackground from '../../../App/Components/SearchForm/SearchBackground';
import Input from '../../Input/Input';
import { SelectControl } from '../../../App/Components/SelectControl/SelectControl';
import { ExportExcel } from '../../../App/Components/Excelexport/Excelexport';
import { searchFields } from './searchInput';
import Checkbox from '../../../App/Components/Checkbox/Checkbox';

const AdvancedSearch = ({
  query,
  setQuery,
  workWithEmployees,
  getExcelData,
}) => {
  let dropDownOptions = [];

  const handleDropdownChange = (fieldName, selectedOption) => {
    if (!selectedOption) {
      selectedOption = {
        value: '',
        id: null,
      };
    }
    setQuery((prevQuery) => ({
      ...prevQuery,
      [fieldName]: {
        ...prevQuery[fieldName],
        value: selectedOption.value,
        id: selectedOption.id?.trim(),
      },
    }));
  };

  const onChange = (e, fieldName) => {
    const val = e.target.value;
    setQuery({ ...query, [fieldName]: val });
  };

  return (
    <SearchBackground>
      <div className="work-with-employees-search">
        <div className="work-with-employees-search-flex">
          {searchFields?.map((field, index) => {
            const {
              fieldType,
              fieldName,
              label,
              name,
              required,
              Children,
              optionName,
              dropdownTitle,
              type,
            } = field;

            if (typeof Children === 'function') {
              if (workWithEmployees) {
                dropDownOptions = Children(workWithEmployees[optionName]);
              }
            }

            switch (fieldType) {
              case 'input':
                return (
                  <div className="input-container" key={index}>
                    <Input
                      type={type}
                      inputTitle={label}
                      name={name}
                      requiredFiled={required}
                      onChange={(e) => onChange(e, name)}
                      className="has-border"
                    />
                  </div>
                );
              case 'dropdown':
                return (
                  <div className="select-container" key={index}>
                    <SelectControl
                      selectBoxTitle={dropdownTitle}
                      requiredFiled={required}
                      options={dropDownOptions || []}
                      onChange={(selectedOption) =>
                        handleDropdownChange(fieldName, selectedOption)
                      }
                    />
                  </div>
                );
              case 'checkbox':
                return (
                  <Checkbox
                    key={index}
                    name={name}
                    label={label}
                    checked={query[name]}
                    onChange={(e) =>
                      setQuery({ ...query, [name]: e.target.checked })
                    }
                  />
                );

              default:
                return null;
            }
          })}
          <div className="L-employees-count">
            <p>Քանակը </p>
            <strong>{workWithEmployees?.emplCount}</strong>
          </div>
        </div>

        <div className="G-flex-justify-end">
          <ExportExcel
            getExcelData={getExcelData}
            fileName="Գործողություններ աշխատակիցների հետ"
          />
        </div>
      </div>
    </SearchBackground>
  );
};

export default AdvancedSearch;
