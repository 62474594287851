import React from 'react';
import Alert from '../../../App/Components/Alert';
import useIsMounted from '../../hooks/useIsMounted';
import WarehouseForm from './WarehouseForm';
import useWarehouse from '../../hooks/useWarehouse';

const EventAddForm = ({ handleClose, params }) => {
  const { addNewWarehouse, errorMess, setErrorMess } = useWarehouse();

  const isMounted = useIsMounted();

  const onAddHandler = async (values, resetForm) => {
    try {
      await addNewWarehouse(values, handleClose, params);
      if (isMounted && !errorMess) {
        resetForm();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <>
      <WarehouseForm onSubmit={onAddHandler} handleClose={handleClose} />
      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default EventAddForm;
